/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Rizki Nida Chaerulsyah
==============================================================================================*/
@import "sass-lib";
$f_bold:"proxima_nova_altbold";
$f_semi:"proxima_nova_ltsemibold";
button{ outline: none;;}
#middle{
    .our-clientnew{
        z-index:4;background:#eee;
        h6{margin-bottom: 20px;}
        a.blueBorderButtton {width:300px;margin:auto auto 60px;display: block;}
        .box{
            position: relative;
            a{display: block;position:relative;overflow:hidden;
              img{max-width: 100%;@include transition(0.15s ease-out all);display: block;}
              >img{@include filter(grayscale(100));}
              &:hover{
                  >img{@include filter(grayscale(0));@include transform(scale(1.1))} 
              }
            }
            .logo{
                position: absolute;width:100px;height:80px;background: url(../images/material/logo-bg.png);z-index:1;
                img{position: absolute;top:0;bottom:0;right:0;left:0;margin:auto;}
            }
        }
        .slick-arrow{
            position: absolute;bottom:0;right:0;left:0;margin:auto;width: 50px;height:50px;background:#d7d7d7 ;
            border:none;text-indent: -9999px;z-index:1;bottom:-25px;
            &:before{content:"";position: absolute;width:0;height: 100%;left:0;top:0;background: #004478;@include transition(0.15s ease-out all)}
            &:after{
                content:"";position: absolute;width:9px;height:23px;background: url(../images/material/arr-slider.png) no-repeat;top:0;bottom:0;left:0;right:0;
                margin: auto;
            }
            &.slick-prev{
                left: -50.5px;background-position: left top;
                &:before{left:auto;right:0;}
                &:after{background-position: top left;}
            }
            &.slick-next{
                right: -50.5px;background-position: right top;
                &:after{background-position: top right;}
            }
            &:hover{
                &:before{width: 100%;}
                &:after{background-position-y: bottom;}
            }
        }

    }
    section.case_studies{
        padding-top:80px;z-index:3;
        .nav_slide{
            @include boxShadow(0px 0px 0px !important);
            .spar{display: none;}
            .direction{            
                height:0;
                a{
                    position: absolute;bottom:-25px;right:0;left:0;margin:auto;width: 50px;height:50px;background:#d7d7d7 ;
                    border:none;text-indent: -9999px;z-index:1;padding:0;font-size:0;
                    &:before{content:"";position: absolute;width:0;height: 100%;left:0;top:0;background: #004478;@include transition(0.15s ease-out all)}
                    &:after{
                        content:"";position: absolute;width:9px;height:23px;background: url(../images/material/arr-slider.png) no-repeat;top:0;bottom:0;left:0;right:0;
                        margin: auto;
                    }
                    &.prev_{
                        left: -50.5px;background-position: left top;
                        &:before{left:auto;right:0;}
                        &:after{background-position: top left;}
                    }
                    &.next_{
                        right: -50.5px;background-position: right top;
                        &:after{background-position: top right;}
                    }
                    &:hover{
                        &:before{width: 100%;}
                        &:after{background-position-y: bottom;}
                    }
                    span{display: none;}
                }
            }
        }
    }

    .contact-new{
        padding:110px 0;background:#eef4f8 url(../images/content/bg_contact_2.jpg) no-repeat bottom ;

        .btn{
            position: relative;overflow: hidden;display: block;background:#008bd0;font-size: 20px;font-weight: 900;color:#FFF;text-align: center;
            text-transform: uppercase;border:none;@include transition(0.2s linear color);
            .hover{
                position: absolute;width:0;height:120%;left:0;right:0;top:0;bottom:0;margin:auto;background: #00548e;@include transform(skew(30deg));z-index: 0;
            }
        }
        .btn:hover{color:#69cdff;}
        .wrapper{
            max-width:1300px;margin:auto;
            &:after{content:"";clear: both;display: block;}     
        }
        .left{
            width:340px;text-transform:uppercase;margin:70px 0 0 0;
            h4{font-size:24px;color:#000;letter-spacing: 2.5px;margin:0 0 20px 0;}
            p{
                font-size:14px;line-height: 30px;letter-spacing:2px;color:#333;
                b{display: block;margin:10px 0 0 0;line-height: 24px;}
                a{color:#333;text-decoration: none}
            }
            .socmed{
                margin:60px 0 0 -8px;
                a{
                    float: left;margin: 0 20px 0 0;
                    &:hover{opacity: 0.7;}
                }
                &:after{content:"";clear: both;display: block;}     
            }
        }

        .box-form{
            position:relative;float: right;width:640px;height: auto;background: #FFF;
            > div {position: relative;background: #FFF}
            .row{
                position: relative;margin:0 30px;height: 84px;border-bottom: 1px solid #f0f0f0;cursor: pointer;
                &.focus{
                    label{color: #FFF;}
                    input,textarea,select{color:#FFF;}
                } 
                &.checkbox-row{
                    height: auto;padding: 75px 0 10px;
                    > label{top:30px;height: auto;}
                    .hover-bg{display: none !important;}
                }
            }
            .row.textarea{height: 144px;}
            .row > label{display: block;position: relative;color:#666;font-size:16px;top:35px;position:absolute;height:16px;margin:auto;
                         @include transition(0.2s all ease-out );height:60px;text-transform:capitalize;
                         span{color:#d40d15;}
            }
            input[type=email],
            input[type=text],            
            textarea,
            select{opacity: 0;display: block;background: none;border: none;padding: 20px 0 0 0;font-size: 24px;color: #333;line-height: 65px;
                   width: 100%;position: relative;width: 100%;outline: none;bottom:-10px;
                   -webkit-appearance: none;
                   -moz-appearance: none;
                   appearance: none;
            }
            select{height: 70px;
                   option{font-size: 16px}
            }
            .selectarr:before{
                content:"";display: block;width: 16px;height: 11px;background: url(../images/material/select_arr.png) no-repeat;
                position: absolute;right:0;top:0;bottom:0;margin:auto;z-index: 1;
            }
            textarea{line-height: 30px;top: 40px;height: 95px;position: relative;padding-top: 0;}
            .btn{position: relative;display: block;height: 85px;width:100%;cursor: pointer;background:#01365e;font-size:16px;letter-spacing:2px;
                 span.hover{background: #000;}
                 span{color:#FFF;}
            }
            .hover-bg{position: absolute;display:block;width:100%;height:100%;bottom:-1px;left:-38px;padding:0 38px 1px;background: #008bcf;
                      opacity:0;
            }
            .checkbox_wrap label {
                float: left;
                margin: 0 35px 15px 2px;
                padding:0 0 0 20px;                
                font-size: 12px;
                letter-spacing: 1px;text-transform: capitalize;font-size:13px;position:relative;
                input{position: absolute;left:0;top:1px}
            }
            .c-tab{display: none;}
            .box-capthca{
                padding:20px 0 20px 30px;

            }

        }
        .box-form:before{
            content:"";display: block;width:100%;height:100%;position: absolute;left:0;top:0;padding-bottom:110px;padding-right: 410px;
            background: url(../images/material/bg-connect-form.png)no-repeat right top;z-index: 0;background-size:  100% 100%;
        }
    }
    .our-com{
        background:#FFF;z-index:4;
        .wrapper{
            max-width: 1300px;
            &:after{clear:both;content:'';display: block;}
        }
        h6{width:200px;text-align:left;float:left;font-size: 24px;color:#004578;line-height: 22px;letter-spacing: 6px}
        .right{
            width: 980px;
            a{
                float: left;margin:10px 3% 0 0;width:22%;
                &:first-child{margin-left: 0}
                img{max-width: 100%;}
            }   
        }
    }
    section.contact_top{
        .wrap_navtab .spar,
        .wrap_navtab .spar_3{display: none}
    }
}

.wrap_popup_form {
    .box-capthca{
        padding: 0 0 30px 0;
            .g-recaptcha{margin: auto;width:304px;}
    }
}
*{outline: none;}

.lifein{
    padding:94px 0 0 0;position:relative;background:#FFF;z-index:1;padding-bottom:100px;overflow:hidden;
    .parallax-scroll{}
    .wrapper{
        width:1170px;margin:auto;position:relative;
        &:after{content:'';clear:both;display: block;}     
    }
    > div{margin:0 65px;position:relative;}
    .li-banner{
        background: url(../images/material/bg-li-1.jpg) no-repeat center;padding:70px 0 40px;
        .wrapper{width:1400px;}
        .left{
            width:630px;@include boxShadow(0px 0px 100px -35px);
            img{display: block;max-width:100%;}
        }
        .caption{
            position: absolute;width:600px;height: 300px;right:0;top:0;bottom:0;margin:auto;
            h2{
                position:relative;font-size:55px;color:#FFF;line-height: 60px;text-transform: uppercase;margin:0 0 35px 0;font-family:$f_bold;
                span{position: absolute;}
                span.line-1{width: 504px;height: 142px;background: url(../images/material/bg-title-h.png) no-repeat center;top:-18px;left:-53px;}
                span.line-2{width: 451px;height: 165px;background: url(../images/material/bg-title-v.png) no-repeat center;top:-31px;left:-5px;}
                span.line-3{width: 456px;height: 133px;background: url(../images/material/bg-title-dot.png) no-repeat center;left:-8px;top:-6px}

            }
            p{font-size: 30px;color:#FFF;line-height: 40px;font-family: "proxima_novalight";text-transform: uppercase;}
        }
        .bg-line{
            position: absolute;width:409px;height: 353px;background: url(../images/material/bg-li-1-bottom.png) no-repeat;bottom:-180px;
        }
    }
    .workhard{
        padding:110px 0 0 0;color:#333;;
        .left{
            width:285px;
            h4{font-size:40px;line-height: 45px;text-transform: uppercase;margin:0 0 20px;font-family:$f_bold;}
            p{font-size: 20px;line-height: 24px;}
        }
        .right{ 
            width:760px;
            .box{
                width:355px;float:left;margin:0 50px 40px 0;padding:0 0 0 90px;@include boxSizing(border-box);position:relative;
                > img{position: absolute;left:0;top:15px;margin:auto;}
                &:nth-child(2n){margin-right:0}
                &:nth-child(2n+1){clear: both;}
                h6{font-family: $f_bold;margin:0 0 10px;line-height: 24px;font-size:20px;text-transform: uppercase;}
                p{font-size: 17px;line-height: 24px;}
            }

            &:after{content:'';clear:both;display: block;}     
        }
        .illus{
            margin: 50px 0 0 0;text-align: center;
        }

    }
    .li-testi{
        padding:0 0 250px;background: url(../images/material/bg-li-2.jpg) no-repeat top center;

        .bg-line{
            position: absolute;width:485px;height: 382px;background: url(../images/material/bg-li-2-bottom.png) no-repeat;bottom:-192px;right:-70px;
        }

        .left{
            width: 570px;height:300px;position:absolute;top:0;bottom: 0;left:0;margin:auto;
            .slick-arrow{display: none !important;}  
            p{font-size: 30px;color:#FFF;line-height:40px;padding:0 0 0 30px;
              &:before,&:after{
                  content:"";display: inline-block;width:19px;height:15px;background: url(../images/material/string-ico-1.png) no-repeat;
                  position:relative;margin:0 0px 10px -27px;
              }
              &:after{
                  background: url(../images/material/string-ico-2.png) no-repeat;margin:0 0px -8px 3px;
              }

            }
            h6{font-family: $f_bold;font-size:20px;text-transform: uppercase;color:#FFF;padding:0 0 0 30px;
               &:before{content:"";display: block;width: 80px;height:6px;background: #FFF;margin:35px 0 20px 0;}
            }
        }
        .right{
            width:475px;@include boxShadow(0px 30px 120px -45px);
            img{display: block;}
            .slick-arrow{
                position: absolute;width:70px;height:70px;bottom:0;padding:0;border:none;background: #FFF;display: block;@include transition(0.15s linear all);
                text-indent:-9999px;
                &:after{content:"";display: block;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;width:18px;height: 32px;
                        background: url(../images/material/arrow-li.png) no-repeat ;
                }
                &.slick-prev{
                    left:-70.5px;;
                    &:after{background-position: top left;}
                }
                &.slick-next{
                    left:0.5px;;
                    &:after{background-position: top right;}
                }
                &:hover{
                    background:#246c8c;
                    &:after{background-position-y: bottom;}
                }
            }
        }

    }
    .li-events{
        >div:after{content:"";display: block;clear:both}
        .box{
            width:370px;position:relative;margin:0 30px 50px 0;float:left;
            img{display: block;}
            .text{
                position: relative;padding:45px 40px;background: #666;width:320px;height: 130px;left:0;@include boxSizing(border-box);
                color:#FFF;text-transform:uppercase;margin-top:-150px;
                small{display: block;font-size: 16px;margin: 0 0 5px 0;}
                h6{font-size:20px;font-family: $f_semi;margin:0;}
            }
            &:nth-child(1n) .text{background: #d31536;}
            &:nth-child(2n) .text{background: #334ba8;}
            &:nth-child(3n) .text{background: #248f5b;}
            &:nth-child(4n) .text{background: #e19024;}
            &:nth-child(5n) .text{background: #0e8e9b;}
            &:nth-child(6n) .text{background: #222e3e;} 

            &:nth-child(3n) {margin-right: 0;}
        }
        .load-more{
            color:#b1b1b1;display: block;position: relative;display:block;text-align:center;font-size:14px;font-family:$f_semi;@include transition(0.15s linear all);
            margin-top:120px;
            span{background: #FFF;padding:0 20px;display:inline-block;position: relative;z-index: 1;}
            &:after{
                content:"";position: absolute;top:0;bottom:0;right:0;left:0;height: 1px;background: #e1e1e1;margin:auto;@include transition(0.15s linear all);
            }
            &:hover{
                color:#01365e;
                &:after{background:#01365e;}
            }
        }
    }
    .li-tagline{
        background: url(../images/material/bg-li-3.jpg) no-repeat;height:500px;position:relative;
        .caption{
            width:1140px;height:120px;position: absolute;top:0;bottom:0;right:0;left:0;margin:auto;text-align: center;
            p{font-size: 30px;color:#FFF;margin:0 0 30px 0;}
            a{
                width:220px;display: block;margin:auto;height: 44px;border:3px solid #FFF;font-size:18px;line-height: 44px;color:#FFF;
                font-family: $f_semi;text-transform: uppercase;text-decoration: none;@include transition(0.15s linear all);
                &:hover{color:#01365e;background: #FFF;}
            }
        }
    }
}

footer {
    height: 105px;
    .footer_content{
        height: 105px;
    }
}
@media (min-width: 0px) and (max-width: 1600px) {
    .lifein{
        img{max-width: 100%;}
        .wrapper{width:1000px}
        > div{margin: 0 40px}
        .li-banner{
            background-size:cover;
            .bg-line{width:300px;background-size: 100% auto;bottom: -223px}
            .wrapper{
                width:1100px;
                .left{width:43%;}
                .caption{
                    width: 45%;
                    h2{font-size: 33px;line-height:45px;
                       span{@include transform(scale(0.7));margin:-12px 0 0 -70px;}
                    }     
                    p{font-size: 24px;line-height: 32px}
                }
            }
        }
        .workhard{
            .left{
                width:260px;
                h4{font-size:30px;line-height: 33px;margin-bottom: 15px}  
                p{font-size: 18px;}
            }
            .right{
                width:660px;
                .box{
                    width:48%;margin:0 4% 20px 0; padding-left: 65px;
                    img{width: 45px;top:10px}
                    h6{font-size:18px;line-height: 22px;margin-bottom: 10px}
                    p{font-size: 15px;line-height: 20px}
                }   
            }
            .illus{margin:0 0 70px 0}
        }
        .li-testi{
            padding-bottom:8%;background-size:cover;margin-bottom:70px;
            .bg-line{bottom: -325px;width:350px;background-size: 100%}
            .left{
                width:50%;height:230px;
                p{font-size:22px;line-height: 32px;}
                h6{margin: 0;font-size:20px;
                   &:before{height: 4px}
                }
            }
            .right{
                width:40%;
                .slick-arrow{
                    width:50px;height:50px; 
                    &:after{@include transform(scale(0.8))}
                    &.slick-prev{left: -50.5px;}
                }

            }
        }
        .li-events{
            .box{
                width: 32%;margin-right: 2%;
                .text{
                    width:85%;padding:30px;height: 105px;
                    small{font-size:14px;}
                    h6{font-size:18px}
                }


            }

        }

    }
}

@media (min-width: 0px) and (max-width: 1440px) {
    #middle{

        .contact-new .wrapper {width:auto;margin:0 80px;}
        .our-com{ 
            padding:45px 0 0 0;
            .wrapper{
                width:auto;margin:0 80px;
            }
            h6{float:none;text-align: center;width:100%;margin:0 0 20px ;}
            .right{
                float:none;display: block;width:auto;padding:0 0 40px 0;
                a{float:left;display: block;width:25%;margin:0;text-align: center;
                  img{max-width: 80%;}
                }
                &:after{
                    content:"";display: block;clear: both;
                }
            }
        }
    }
}
@media (min-width: 0px) and (max-width: 1280px) {
    #middle{
        .our-clientnew .box{
            .logo{@include transform(scale(0.6));top:-16px;left:-20px}
        }
        .contact-new{
            .wrapper {margin:0 40px;}   
            .left{
                width:280px;
                p{font-size: 13px;line-height: 26px;
                  b{line-height: 22px}
                }
            }
            .box-form{
                width:500px;
                &:before{left:0;padding-right: 318px}
            }
        }
        .our-com{ 
            padding:45px 0 0 0;
            .wrapper{
                margin:0 40px;
            }
            h6{}

        }
    }
}
@media (min-width: 0px) and (max-width: 900px) {
    #middle{

        .contact-new{
            .wrapper {position:relative;padding-bottom: 300px;}   
            .left{
                width:auto;position:absolute;bottom:0;left:0;right:0;margin:auto;text-align:center;z-index:2;
                p{font-size: 13px;line-height: 26px;
                  b{line-height: 22px}
                }
                .socmed{width: 221px;margin:auto;
                        a:last-child{margin:0;}
                }
            }
            .box-form{
                float: none;margin:auto;
                &:before{display: none;}      
            }
            .right{margin:auto;float:none;display: block;z-index: 1;position: relative;}
        }
        .our-com{ 
            padding:45px 0 0 0;
            .wrapper{
                margin:0 40px;
            }
            h6{}

        }
    }
}
@media (min-width: 0px) and (max-width: 720px) {
    #middle{
        section.case_studies{
            padding-top: 0;padding-bottom:50px;
            .nav_slide{
                height: 0;
                .direction{            
                    a{
                        width:50%;bottom:-50px;
                        &.prev_{
                            left: 0;right:auto;
                            &:after{left:auto;right:30px}         
                        }
                        &.next_{
                            right: 0;left:auto;
                            &:after{right:auto;left:30px}         
                        }
                    }
                }
            }
        }
        .our-clientnew{
            padding-bottom:50px;
            > h2,
            > h6{max-width: 80%;margin-left:auto;margin-right:auto;}
            .slick-arrow{
                bottom: -50px;width:50%;
                &.slick-next{
                    left: auto;right:0;
                    &:after{right:auto;left:30px}         
                }         
                &.slick-prev{
                    right: auto;left:0;
                    &:after{left:auto;right:30px}         
                }         
            }
        }
        .contact-new{
            padding:80px 0;
            .wrapper{margin:0 30px;}
            .box-form{width: 100%;}
        }
        .our-com{
            padding:30px 0 0 0;
            .wrapper{ margin:0 30px;}
            h6{font-size:18px}
            .right{
                padding-bottom:20px;
                a{width: 50%;margin:0 0 15px }
            }
        }
    }
    .wrap_popup_form {
        .box-capthca{


        }
    }

}

@media (min-width: 0px) and (max-width: 425px) {
    .g-recaptcha{@include transform(scale(0.9))}

    #middle{
        .contact-new{
            padding:60px 0;
            .wrapper{width:auto;margin:0;padding-bottom: 280px}
            .box-form .row {margin: 0 20px;}
        }
        .our-com{
            .right{
                a{width:100%;}
            }
        }
        .contact-new .box-form .box-capthca{
            padding:20px 0 20px 5px;
        }
    }   

    footer .footer_content p {
        margin-top: 24px;
    }
}